import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-provision',
  templateUrl: './provision.component.html',
  styleUrls: ['./provision.component.scss']
})
export class ProvisionComponent implements OnInit {
public costs = [{
    price: '1 500 zł brutto',
    description: 'motocykl lub quad'
  }, {
    price: '2 500 zł brutto',
    description: 'samochód do 20 000 $'
  }, {
    price: '3 500 zł brutto',
    description: 'samochód od 20 000 $ do 49 999 $'
  }, {
    price: '5 000 zł brutto',
    description: 'samochód od 50 000 $'
  }, {
    price: 'ustalana indywidulanie',
    description: 'łodzie i inne pojazdy ponad gabarytowe'
  }];
  public displayedColumns = ['price', 'description'];

  ngOnInit(): void {
  }

}
