<span class="anchor" id="auctions"></span>
<mat-card class="mat-elevation-z5 auctions-box">
  <app-heading [underline]="true">Licytacje na jakich kupujemy</app-heading>
  <div class="auctions">
    <div class="auctions-container">
      <div *ngFor="let auction of auctions; let i = index" class="auctions-box__icon-container" [ngClass]="{ current: auction.name === current.name, 'scale-up-center': auction.name === current.name}" (click)="changeAuction(i)">
        <img class="auctions-box__icon" [src]="'../../assets/auctions/' + auction.icon" [alt]="auction.name">
        <p class="auctions-box__title">{{auction.name}}</p>
      </div>
    </div>
    <div class="auctions-mobile-container">
      <fa-icon (click)="changeAuction(auctions.indexOf(current) - 1)" [icon]="chevronLeft"></fa-icon>
      <div class="auctions-box__icon-container current">
        <img class="auctions-box__icon" [src]="'../../assets/auctions/' +  current.icon" [alt]=" current.name">
        <p class="auctions-box__title">{{current.name}}</p>
      </div>
      <fa-icon (click)="changeAuction(auctions.indexOf(current) + 1)" [icon]="chevronRight"></fa-icon>
    </div>
  </div>
</mat-card>
<app-auction-details [auction]="current"></app-auction-details>
