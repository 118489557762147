<span class="anchor" id="contact"></span>
<mat-card class="mat-elevation-z5 contact">
  <app-heading [underline]="true">Kontakt</app-heading>
  <div class="contact__content">
    <div class="contact__left">
      <form class="contact__form" ngForm="form">
        <div class="contact__form__line">
          <mat-form-field class="contact__form__input">
            <mat-label>Imię i nazwisko</mat-label>
            <input matInput #name>
          </mat-form-field>
          <mat-form-field class="contact__form__input">
            <mat-label>Firma</mat-label>
            <input matInput #company>
          </mat-form-field>
        </div>
        <div class="contact__form__line">
          <mat-form-field class="contact__form__input">
            <mat-label>Telefon</mat-label>
            <input matInput #phone>
          </mat-form-field>
        </div>
        <div class="contact__form__line">
          <mat-form-field class="contact__form__textarea">
            <mat-label>Wiadomość</mat-label>
            <textarea #message matInput maxlength="120"></textarea>
            <mat-hint align="end">{{message.value?.length || 0}}/120</mat-hint>
          </mat-form-field>
        </div>
        <div class="contact__form__line">
          <mat-checkbox #checkbox class="contact__form__line__checkbox-label example-margin">Wyrażam zgodę na przetwarzanie moich danych osobowych.</mat-checkbox>
        </div>
        <a
          [disabled]="!checkbox.checked"
          [href]="'mailto:kensy5@wp.pl?subject=Kontakt&body='+'Imię i nazwisko: ' + name.value + '%0D' + 'Firma: ' + company.value + '%0D' + 'Telefon: ' + phone.value + '%0D' + 'Wiadomość: ' + message.value"
          mat-flat-button color="primary">Wyślij</a>
      </form>
      <div class="phone-container">
        <h3>Jesteśmy dostępni również pod numerem telefonu:</h3>
        <mat-chip-list class="mat-chip-list">
            <mat-chip color="primary" selected style="font-size: 24px">+48 606 627 408</mat-chip>
        </mat-chip-list>
      </div>
    </div>
    <div class="contact__right"><img src="../../assets/crane.png" alt="crane"></div>
  </div>
</mat-card>
