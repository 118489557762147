<mat-toolbar class="mat-elevation-z5">
  <div class="logo-container">
    <img class="logo" src="../assets/logo.png" alt="logo">
    <span class="logo-label">autakensik<span class="logo-label-dot">.</span>pl</span>
  </div>
  <ul class="navigation">
    <li class="navigation__item active"><a class="navigation__item__link" href="#">Home</a></li>
    <li class="navigation__item"><a class="navigation__item__link" href="#auctions">Licytacje</a></li>
    <li class="navigation__item"><a class="navigation__item__link" href="#provision">Prowizja</a></li>
    <li class="navigation__item"><a class="navigation__item__link" href="#transport">Transport</a></li>
    <li class="navigation__item"><a class="navigation__item__link" href="#contact">Kontakt</a></li>
  </ul>
  <div class="navigation-mobile">
    <input type="checkbox" class="navigation-mobile__checkbox" id="navi-toggle">

    <label for="navi-toggle" class="navigation-mobile__button">
      <span class="navigation-mobile__icon">&nbsp;</span>
    </label>

    <div class="navigation-mobile__background">&nbsp;</div>

    <nav class="navigation-mobile__nav">
      <ul class="navigation-mobile__list">
        <li class="navigation-mobile__item"><a href="#" class="navigation-mobile__link">Home</a></li>
        <li class="navigation-mobile__item"><a href="#auctions" class="navigation-mobile__link">Licytacje</a></li>
        <li class="navigation-mobile__item"><a href="#provision" class="navigation-mobile__link">Prowizja</a></li>
        <li class="navigation-mobile__item"><a href="#transport" class="navigation-mobile__link">Transport</a></li>
        <li class="navigation-mobile__item"><a href="#contact" class="navigation-mobile__link">Kontakt</a></li>
      </ul>
    </nav>
  </div>
</mat-toolbar>
<div class="page">
  <div class="container">
    <picture>
      <source media="(max-width: 600px)" srcset="../assets/banner-amg-small.png">
      <img src="../assets/banner-amg.png" alt="banner">
    </picture>
    <img class="flag" src="../assets/flag.png" alt="flag">
    <div class="blur-box slide-in-left">
    </div>
    <h1 class="blur-text slide-in-left">Wylicytujemy i przetransportujemy Twoje auto</h1>
  </div>
  <mat-card class="mat-elevation-z5 welcome-box">
    <div class="welcome-box__left">
      <app-heading>Import aut z USA i Kanady</app-heading>
      <div class="welcome-box__paragraph welcome-box__paragraph--1">
        Dlaczego warto rozważyć sprowadzanie aut z USA lub Kanady?
      </div>
      <p class="welcome-box__paragraph">
        Okazuje się, że import aut z USA na zamówienie jest o wiele bardziej opłacalny,
        niż mogłoby się wydawać a opłaty wcale nie są takie straszne.
        Sprowadzanie auta z Ameryki do Polski to oszczędności nawet do 40% w stosunku do cen pojazdów z rynku europejskiego! Warto wziąć pod uwagę fakt, że pojazdy oferowane na rynku amerykańskim i kanadyjskim są znacznie lepiej wyposażone. Jeżeli chcesz zaoszczędzić swoje pieniądze i otrzymać auto oferujące więcej możliwości ta strona jest właśnie dla Ciebie!
      </p>
      <a mat-flat-button color="primary" href="#purchase-process">Jak zamawiać</a>
      <img class="welcome-box__left__car" src="../assets/lambo.png" alt="lambo">
    </div>
    <div class="welcome-box__right">
      <img class="welcome-box__right__car" src="../assets/lambo.png" alt="lambo">
    </div>
  </mat-card>
  <app-auctions></app-auctions>
  <app-purchase-process></app-purchase-process>
  <app-transport></app-transport>
  <app-provision></app-provision>
  <app-contact></app-contact>
  <app-times></app-times>
</div>
<app-footer></app-footer>
