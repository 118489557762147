<span class="anchor" id="purchase-process"></span>
<mat-card class="mat-elevation-z5 purchase-process">
  <app-heading [underline]="true">Przebieg zakupu</app-heading>
  <img src="../../assets/map.png" alt="world map">
  <div class="purchase-process__circle purchase-process__circle--1">
    <img src="../../assets/purchase-process/auction-icon.png" alt="auction">
    <p>Licytacja</p>
    <div class="purchase-process__circle__content">
      <h2>Licytacja</h2>
      <h3>Wybierz auto na jednym z portali aukcyjnych, prześlij nam wybrany pojazd a my wylicytujemy go dla Ciebie.</h3>
    </div>
  </div>
  <div class="purchase-process__circle purchase-process__circle--2">
    <img src="../../assets/purchase-process/ship-icon.png" alt="ship">
    <p>Transport</p>
    <div class="purchase-process__circle__content">
      <h2>Transport do Polski</h2>
      <h3>Po dokonaniu przelewu do USA samochód przewożony jest do najbliższego portu w USA (New York, Chicago lub Los Angeles), zapakowywany do kontenera, następnie drogą morską przetransportowywany jest do Portu w Gdyni lub Bremerhaven.</h3>
    </div>
  </div>
  <div class="purchase-process__circle purchase-process__circle--3">
    <img src="../../assets/purchase-process/briefing-icon.png" alt="briefing">
    <p>Odprawa</p>
    <div class="purchase-process__circle__content">
      <h2>Odprawa celna pojazdu</h2>
      <h3>Po dostarczeniu pojazdu do Polski auto przechodzi procedurę odprawy celnej (tłumaczenie dokumentów, rzeczoznawca itp.).</h3>
    </div>
  </div>
  <div class="purchase-process__circle purchase-process__circle--4">
    <img src="../../assets/purchase-process/registration-icon.png" alt="registration">
    <p>Akcyza</p>
    <p>Rejestracja</p>
    <div class="purchase-process__circle__content">
      <h2>Rejestracja pojazdu i wydanie pojazu właścicielowi</h2>
      <h3>Szerokiej drogi!</h3>
    </div>
    </div>
</mat-card>
