<span class="anchor" id="provision"></span>
<mat-card class="mat-elevation-z5 provision">
  <app-heading [underline]="true">Prowizje</app-heading>
  <table mat-table [dataSource]="costs">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef> Nasza prowizja</th>
      <td mat-cell *matCellDef="let element"> {{element.price}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"> {{element.description}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</mat-card>
