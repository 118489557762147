import {Component, Input, OnInit} from '@angular/core';
import {Auction} from "../auction";

@Component({
  selector: 'app-auction-details',
  templateUrl: './auction-details.component.html',
  styleUrls: ['./auction-details.component.scss']
})
export class AuctionDetailsComponent implements OnInit {

  @Input() auction: Auction;

  constructor() { }

  ngOnInit(): void {
  }

}
