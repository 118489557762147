<mat-card class="mat-elevation-z5 times">
  <app-heading [underline]="true">Czasy w USA</app-heading>
  <div class="times__container">
    <div class="times__item">
      <h3>Los Angeles</h3>
      <p>{{ losAngelesTime$ | async | date: 'HH:mm:ss'}}</p>
    </div>
    <div class="times__item">
      <h3>Chicago</h3>
      <p>{{ chicagoTime$ | async | date: 'HH:mm:ss'}}</p>
    </div>
    <div class="times__item">
      <h3>New York</h3>
      <p>{{ newYorkTime$ | async | date: 'HH:mm:ss'}}</p>
    </div>
  </div>
</mat-card>
