<span class="anchor" id="transport"></span>
<mat-card class="mat-elevation-z5 transport">
  <app-heading [underline]="true">Transport</app-heading>
  <h3>Transport pojazdów z USA relizowany jest drogą morską z portów w Nowym Jorku, Chicago oraz Los Angeles:</h3>
  <mat-tab-group style="width: 100%;">
    <mat-tab label="New York">
      <table mat-table [dataSource]="newYork">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="vehicle">
          <th mat-header-cell *matHeaderCellDef>Rodzaj pojazdu</th>
          <td mat-cell *matCellDef="let element"> {{element.vehicle}} </td>
        </ng-container>

        <ng-container matColumnDef="gdynia">
          <th mat-header-cell *matHeaderCellDef>Gdynia</th>
          <td mat-cell *matCellDef="let element"> {{element.gdynia}} </td>
        </ng-container>

        <ng-container matColumnDef="bremerhaven">
          <th mat-header-cell *matHeaderCellDef>Bremerhaven</th>
          <td mat-cell *matCellDef="let element"> {{element.bremerhaven}} </td>
        </ng-container>

        <ng-container matColumnDef="rotterdam">
          <th mat-header-cell *matHeaderCellDef>Rotterdam</th>
          <td mat-cell *matCellDef="let element"> {{element.rotterdam}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-tab>
    <mat-tab label="Chicago">
      <table mat-table [dataSource]="chicago">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="gdynia">
          <th mat-header-cell *matHeaderCellDef>Gdynia</th>
          <td mat-cell *matCellDef="let element"> {{element.gdynia}} </td>
        </ng-container>

        <ng-container matColumnDef="bremerhaven">
          <th mat-header-cell *matHeaderCellDef>Bremerhaven</th>
          <td mat-cell *matCellDef="let element"> {{element.bremerhaven}} </td>
        </ng-container>

        <ng-container matColumnDef="rotterdam">
          <th mat-header-cell *matHeaderCellDef>Rotterdam</th>
          <td mat-cell *matCellDef="let element"> {{element.rotterdam}} </td>
        </ng-container>

        <ng-container matColumnDef="vehicle">
          <th mat-header-cell *matHeaderCellDef>Rodzaj pojazdu</th>
          <td mat-cell *matCellDef="let element"> {{element.vehicle}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-tab>
    <mat-tab label="Los Angeles">
      <table mat-table [dataSource]="losAngeles">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="gdynia">
          <th mat-header-cell *matHeaderCellDef>Gdynia</th>
          <td mat-cell *matCellDef="let element"> {{element.gdynia}} </td>
        </ng-container>

        <ng-container matColumnDef="bremerhaven">
          <th mat-header-cell *matHeaderCellDef>Bremerhaven</th>
          <td mat-cell *matCellDef="let element"> {{element.bremerhaven}} </td>
        </ng-container>

        <ng-container matColumnDef="rotterdam">
          <th mat-header-cell *matHeaderCellDef>Rotterdam</th>
          <td mat-cell *matCellDef="let element"> {{element.rotterdam}} </td>
        </ng-container>

        <ng-container matColumnDef="vehicle">
          <th mat-header-cell *matHeaderCellDef>Rodzaj pojazdu</th>
          <td mat-cell *matCellDef="let element"> {{element.vehicle}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-tab>
  </mat-tab-group>
  <div class="description-container">
    <div>
      <h3>Wymiary kontenera:</h3>
      <p>kontener High Cube 40ft,</p>
      <p>wysokość w drzwiach 2.58 m</p>
      <p>1 ft = 30,48 cm, 1 lbs = 0,4055 kg</p>
      <hr>
      <p><b>Średnie wymiary wewnętrzne</b></p>
      <p>Długość: 12,02m</p>
      <p>Szerokość: 2,30m</p>
      <p>Wysokość: 2,38m</p>
      <p><b>Średnie wymiary drzwi</b></p>
      <p>Długość: 2,33m</p>
      <p>Szerokość: 2,28m</p>
      <p>Średnia kubatura: 67m2</p>
    </div>
    <img src="../assets/container.png" alt="container">
  </div>
</mat-card>
