import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeadingComponent } from './heading/heading.component';
import { AuctionsComponent } from './auctions/auctions.component';
import { PurchaseProcessComponent } from './purchase-process/purchase-process.component';
import { CarsComponent } from './cars/cars.component';
import { CarsItemComponent } from './cars/cars-item/cars-item.component';
import { ContactComponent } from './contact/contact.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FooterComponent } from './footer/footer.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { AuctionDetailsComponent } from './auctions/auction-details/auction-details.component';
import {FormsModule} from "@angular/forms";
import { MatChipsModule} from '@angular/material/chips';
import { TimesComponent } from './times/times.component';
import { ProvisionComponent } from './provision/provision.component';
import {MatTableModule} from '@angular/material/table';
import { TransportComponent } from './transport/transport.component';
import {MatTabsModule} from '@angular/material/tabs';

@NgModule({
  declarations: [
    AppComponent,
    HeadingComponent,
    AuctionsComponent,
    PurchaseProcessComponent,
    CarsComponent,
    CarsItemComponent,
    ContactComponent,
    FooterComponent,
    AuctionDetailsComponent,
    TimesComponent,
    ProvisionComponent,
    TransportComponent,
  ],
  imports: [
    BrowserModule,
    MatToolbarModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    MatCheckboxModule,
    MatInputModule,
    GoogleMapsModule,
    FormsModule,
    MatChipsModule,
    MatTableModule,
    MatTabsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
