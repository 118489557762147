import { Component, OnInit } from '@angular/core';
import {faChevronLeft, faChevronRight, faCoffee} from '@fortawesome/free-solid-svg-icons';
import {Auction} from "./auction";

@Component({
  selector: 'app-auctions',
  templateUrl: './auctions.component.html',
  styleUrls: ['./auctions.component.scss']
})
export class AuctionsComponent implements OnInit {
  auctions: Auction[] = [
    {
      icon: 'copart.png',
      name: 'Copart',
      text: `
        Jak szukać aut na licytacji Copart? <br>
        <p><b><i>Niektóre oferty nie są widoczne na Copart jako model, należy wybrać Markę i ALL MODEL , nie pokazuje m.i Mazda CX7, CX9, Mercedes CLS.</i></b></p>
        Po odnalezieniu oferty proszę wysłać do nas na e-mail kontakt@autakensik.pl takie dane:
        <ul>
          <li>W polu Search By TYPE (wyszukiwarce) proszę zaznaczyć, na przykład:</li>
          <li>Vehicle type : Automobile</li>
          <li>Year: 2005 -2011</li>
          <li>Make: Audi</li>
          <li>Model: A6 (można zostawić ALL MODELS, system pokaże wszystkie Audi z wybranych roczników)</li>
          <li>Po wybraniu ALL MODEL okaże się cała lista pojazdów z wybranego rocznika</li>
          <li>Wcisnąć Search</li>
        </ul>
        <p>Po odnalezieniu odpowiadającego auta proszę o spisanie LOT NUMBER  i wysłania go po przez e-mail do usacars.chojnice@gmail.com lub podania przez telefon, a my podamy w jakich granicach (orientacyjnie) może kosztować na aukcji wybrany pojazd.</p>`,
      link: 'https://www.copart.com/pl/lotSearchResults'
    },
    {
      icon: 'iaai.png',
      name: 'Iaai USA',
      text: `
        Jak wyszukiwać pojazdy na IAAI USA? <br>
        <p>Proszę wejść do domu aukcyjnego IAAI USA. Można zmienić język na Polski w prawym górnym rogu. Wejść na Pojazdy, Rozszerzone, Wyszukiwanie. Pozwala na dokładne wyszukiwanie wszystkich ofert z wybranych roczników oraz modeli.</p>
        Wystarczy zaznaczyć:
        <ul>
          <li>Typ pojazdu (wszystko)</li>
          <li>Markę</li>
          <li>Model można nie zaznaczać, system pokaże wszystkie auta z wybranej marki</li>
          <li>Rok Początkowy</li>
          <li>Rok końcowy</li>
          <li>Wciśnij <b>WYSZUKIWANIE</b></li>
        </ul>
        <p>Po odnalezieniu odpowiadającego pojazdu proszę o spisanie nr zapasów podania jego przez telefon lub wysłania e-mailem do kontakt@autakensik.pl, a my określimy jaka może być przewidywana cena pojazdu.</p>`,
      link: 'https://www.iaai.com/Search'
    },
    {
      icon: 'iaai.png',
      name: 'Iaai Kanada',
      text: `
        Jak wyszukiwać pojazdy na IAAI Kanada? <br>
        <p>Proszę wejść do domu aukcyjnego IAAI Kanada. Wejść na rozszerzone wyszukiwanie (<b>advanced search</b>). Pozwala na dokładne wyszukiwanie wszystkich ofert z wybranych roczników oraz modeli.</p>
        Wystarczy wybrać odpowiednie filtry:
        <ul>
          <li>Markę</li>
          <li>Model można nie zaznaczać, system pokaże wszystkie auta z wybranej marki</li>
          <li>Rok Początkowy</li>
          <li>Rok końcowy</li>
          <li>Wciśnij <b>APPLY FILTERS</b></li>
        </ul>
        <p>Po odnalezieniu odpowiadającego pojazdu proszę o spisanie nr zapasów podania jego przez telefon lub wysłania e-mailem do kontakt@autakensik.pl, a my określimy jaka może być przewidywana cena pojazdu.</p>`,
      link: 'https://ca.iaai.com/Search/Search'
    },
    {
      icon: 'cars.jpg',
      name: 'Cars.com',
      text: `
        Jak szukać aut na aukcjach Manheim? <br>
        Po odnalezieniu oferty proszę wysłać do nas na e-mail kontakt@autakensik.pl takie dane:
        <ul>
          <li>nazwa licytacji n.p Manheim</li>
          <li>markę, model</li>
          <li>rok</li>
          <li>przebieg</li>
          <li>numer VIN</li>
          <li>lub link ogłoszenia</li>
        </ul>
        <p>Przy niektórych ofertach jest podana cena MMR, to jest
        średnia cena zakupu takiego auta o podobnym przebiegu oraz stanie technicznym.</p>`,
      link: 'https://www.cars.com/'
    },
    {
      icon: 'manheim.png',
      name: 'Manheim',
      text: `
        Jak szukać aut na aukcjach Manheim? <br>
        Po odnalezieniu oferty proszę wysłać do nas na e-mail kontakt@autakensik.pl takie dane:
        <ul>
          <li>nazwa licytacji n.p Manheim</li>
          <li>markę, model</li>
          <li>rok</li>
          <li>przebieg</li>
          <li>numer VIN</li>
          <li>lub link ogłoszenia</li>
        </ul>
        <p>Przy niektórych ofertach jest podana cena MMR, to jest
        średnia cena zakupu takiego auta o podobnym przebiegu oraz stanie technicznym.</p>`,
      link: 'https://www.manheim.com/'
    },
    {
      icon: 'total-resource.png',
      name: 'Total Resource',
      text: `
        Jak szukać aut na Total Resource? <br>
        Wyszukiwanie aut na TOTAL RESOURCE:
        <ul>
          <li>Wpisać rok początkowy i końcowy</li>
          <li>Wpisać markę</li>
          <li>Model lepiej nie wpisywać, na tej licytacji nie ma dużo aut. Ewentualnie wpisać samemu a nie wybierać z listy.</li>
          <li>Przykład na liscie modeli jest Rav4, sugerujemy wpisać (samo) Rav. </li>
          <li>Wtedy system pokaże wszystkie modele z słowem Rav.</li>
        </ul>
        Po wybraniu pojazdu lub motocykla proszę o wysłanie danych pojazdu na e-mail kontakt@autakensik.pl takich jak:
        <ul>
        <li>rocznik auta</li>
        <li>markę</li>
        <li>model</li>
        <li>miejscowość sprzedaży</li>
        <li>nazwę domu aukcyjnego Total Resource</li>
        <li>lub link ogłoszenia</li>
</ul>
        `,
      link: 'https://www.traauctions.com/TraPortal21/Vehicles/Search'
    }
  ];
  current: Auction = this.auctions[0];
  chevronRight = faChevronRight;
  chevronLeft = faChevronLeft;

  constructor() { }

  ngOnInit(): void {
  }

  changeAuction(index: number) {
    if (index < 0) {
      index = this.auctions.length - 1;
    }
    this.current = this.auctions[index % this.auctions.length];
  }
}
