import { Component, OnInit } from '@angular/core';
import {Observable, timer} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-times',
  templateUrl: './times.component.html',
  styleUrls: ['./times.component.scss']
})
export class TimesComponent implements OnInit {
// Define the timezone offsets for Los Angeles, New York, and Chicago
  private losAngelesOffset = -480; // PST is 8 hours behind UTC
  private newYorkOffset = -300; // EST is 5 hours behind UTC
  private chicagoOffset = -360; // CST is 6 hours behind UTC

  public losAngelesTime$: Observable<Date>;
  public newYorkTime$: Observable<Date>;
  public chicagoTime$: Observable<Date>;

  constructor() {
    const clock$ = timer(0, 1000).pipe(
      map(() => {
        // Get the current UTC time
        const now = new Date();

        // Calculate the current time in each time zone
        const losAngelesTime = new Date(now.getTime() + this.losAngelesOffset * 60 * 1000);
        const newYorkTime = new Date(now.getTime() + this.newYorkOffset * 60 * 1000);
        const chicagoTime = new Date(now.getTime() + this.chicagoOffset * 60 * 1000);

        // Return an object with each time zone's current time
        return {
          losAngeles: losAngelesTime,
          newYork: newYorkTime,
          chicago: chicagoTime
        };
      })
    );

    // Extract each time zone's current time into a separate property
    this.losAngelesTime$ = clock$.pipe(map(time => time.losAngeles));
    this.newYorkTime$ = clock$.pipe(map(time => time.newYork));
    this.chicagoTime$ = clock$.pipe(map(time => time.chicago));
  }

  ngOnInit(): void {
  }
}
