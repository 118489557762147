<div class="footer">
  <div class="footer__left">
    <img class="footer__logo" src="../../assets/logo.svg" alt="logo">
    <div class="footer-details-container">
      <div class="footer__details">
        <span class="footer__logo-label">autakensik<span class="footer__logo-label-dot">.</span>pl</span>
        <p>Krzysztof Kensik</p>
        <p>Małe Wędoły 6</p>
        <p>89-642 Rytel woj. Pomorskie</p>
        <p>kensy5@wp.pl</p>
        <p>+48 606 627 408</p>
      </div>
      <div id="cinkciarzPlWidget"></div>
    </div>
    <p class="footer__copyright">© Copyright {{ currentYear }} autakensik.pl Wszelkie prawa zastrzeżone.</p>
    <p><small>Realizacja <a class="codena" href="https://codena.pl">Codena</a></small></p>
  </div>
  <google-map class="footer__right" width="100%" height="100%" [options]="{styles: style}" [center]="{lat: 53.776698, lng: 17.756350}" [zoom]="10">
    <map-marker #marker
                [position]="{lat: 53.776698, lng: 17.756350}"
                ></map-marker>
  </google-map>
</div>
