import { Component, OnInit } from '@angular/core';

const NEW_YORK = [
  { gdynia: '1 000 USD', bremerhaven: '900 USD', rotterdam: '900 USD', vehicle: 'auto osobowe, SUV lub VAN' },
  { gdynia: '1 200 USD', bremerhaven: '1 100 USD', rotterdam: '1 100 USD', vehicle: 'większe' },
  { gdynia: '550 USD', bremerhaven: '450 USD', rotterdam: '450 USD', vehicle: 'motocykl' },
  { gdynia: '550 USD', bremerhaven: '550 USD', rotterdam: '550 USD', vehicle: 'quad' },
  { gdynia: 'indywidualnie', bremerhaven: 'indywidualnie', rotterdam: 'indywidualnie', vehicle: 'inne (łódka, samolot)' },
];
const CHICAGO = [
  { gdynia: '1 250 USD', bremerhaven: '1 150 USD', rotterdam: '1 150 USD', vehicle: 'auto osobowe' },
  { gdynia: '500 USD', bremerhaven: '450 USD', rotterdam: '450 USD', vehicle: 'motocykl' },
  { gdynia: '600 USD', bremerhaven: '550 USD', rotterdam: '550 USD', vehicle: 'quad' },
  { gdynia: '', bremerhaven: '', rotterdam: '', vehicle: '' },
  { gdynia: '', bremerhaven: '', rotterdam: '', vehicle: '' },
];
const LOS_ANGELES = [
  { gdynia: '1 650 USD', bremerhaven: '1 600 USD', rotterdam: '1600 USD', vehicle: 'auto osobowe' },
  { gdynia: '1 650 USD', bremerhaven: '1 600 USD', rotterdam: '1600 USD', vehicle: 'SUV lub VAN' },
  { gdynia: '750 USD', bremerhaven: '500 USD', rotterdam: '500 USD', vehicle: 'motocykl' },
  { gdynia: '750 USD', bremerhaven: '600 USD', rotterdam: '600 USD', vehicle: 'quad' },
  { gdynia: '', bremerhaven: '', rotterdam: '', vehicle: '' },
];

@Component({
  selector: 'app-transport',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.scss']
})
export class TransportComponent implements OnInit {
  public displayedColumns: string[];
  public newYork: any[];
  public chicago: any[];
  public losAngeles: any[];

  constructor() {
    this.displayedColumns = ['vehicle', 'gdynia', 'bremerhaven', 'rotterdam'];
    this.newYork = NEW_YORK;
    this.chicago = CHICAGO;
    this.losAngeles = LOS_ANGELES;
  }
  ngOnInit() {
  }
}
